// Plugins
import i18n from '@/plugins/i18n'

export default function() {
    let reportTypes = [];

    // Missing Documents
    reportTypes.push({
        value: "make-up-exam",
        text: '1- '+i18n.t("make_up_additional_student_list"),
        permission: "studentprogram_missingdocumentexcelexport",
    });

    return reportTypes;

}


